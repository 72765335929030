@import "variables";

@mixin responsiveSpace($property, $size: 1) {
  #{$property}: #{100 * $size}px;
  @media (max-width: $md) {
    #{$property}: #{50 * $size}px;
  }
  @media (max-width: $sm) {
    #{$property}: #{30 * $size}px;
  }
}


@mixin roundBottom($color:$green, $bg:transparent, $border: $green) {
  background: $bg;
  color: $color;
  text-align: center;
  padding: 8px 15px;
  box-shadow: none;
  text-decoration: none;
  border-radius: 30px;
  border: 1px solid $border;
  @include font(1);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s all ease;
  img {
  transition: 0.4s all ease;
  }
  &:hover {
    color: $bg;
    background-color: $color;
    border-color: $color;
    @if($bg == transparent OR $bg == white){
      color: $darkMain;
      img {
          filter: grayscale(100) brightness(0);
      }
    }
  }
  &.icon-right {
    img {
      margin-right: 0;
      margin-left: 0.5em;
    }
  }

  img {
    object-position: left;
    width: 0.8em;
    height: 0.8em;
    object-fit: contain;
    vertical-align: middle;
    margin-right: 0.5em;
  }
}

@mixin font($size:1) {
  font-family: $redHat;
  font-size: #{1 * $size}rem;
}

p {
  @include font();

  &.min {
    @include font(0.9);
  }
}

h1 {
  @include font(1.92);
  font-weight: 400;
}

h2 {
  @include font(1.54);
  font-weight: 400;
}

h3 {
  @include font(1.46);
  font-weight: 400;
}

h4 {
  @include font(1.15);
  font-weight: 400;
}

@mixin pmedium() {
  @include font(0.8);
}

@mixin pmin() {
  @include font(.75);
}

@mixin input() {
  color: $green;
  box-shadow: none;
  border: none;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  background: $darkMain;
  &:placeholder-shown {
    color: $whiteText;
  }
  &::placeholder {
    color: $whiteText;
  }
}
