@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

//FONTS

$redHat: 'Red Hat Text', sans-serif;
$fontSize: 16px;
$green: #29EB4F;
$darkMain: #0B0C0E;
$darkDescription: rgba($darkMain, .70);
$darkContainer: #1A1B1D;
$darkExtra: rgba(#1D1F20, .70);
$grayStroke: rgba(#717577, .10);
$grayExtra: #1A1B1C;
$whiteText: #6D6D6E;
$grayContainer: #202223;
$darkGradient: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 20%, rgba(255,255,255,0) 100%);
$blue: #186AE4;
$red: #EA4335;
$containerColor: #151618;
$inputContainerColor: $grayExtra;
$container: 980px;

$responsive: 1060px;
$responsiveMenu: 920px;
$md: 1150px;
$sm: 840px;
$xs: 620px;

$headerHeight: 84px;

$boxshadow: 2px 2px 15px 0px rgba(0,0,0,0.5);

