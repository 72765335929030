@import "mixins";
@import "variables";
/* You can add global styles to this file, and also import other style files */

body, html {
  font-family: $redHat;
  font-size: $fontSize;
  @media (max-width: $sm) {
    font-size: $fontSize * 0.9;
  }
}

body {
  background: $darkMain;

  &.overflow-hidden {
    overflow: hidden;
  }

  &.profile {
    min-height: 100vh;
    background: {
      image: url("~src/assets/profile_img_fondo.png");
      size: cover;
      position: center;
    };
  }
}

.container {
  max-width: $container;
  margin: 0 auto;
  padding: 0 15px;
}

.custom-icon {
  display: inline-block;
  min-height: 16px;
  min-width: 16px;
  background: {
    position: center;
    size: contain;
    repeat: no-repeat;
  };

  &.delete-icon {
    background-image: url("~src/assets/iconos/icon_borrar.svg");
  }

  &.edit-icon {
    background-image: url("~src/assets/iconos/icono_editar.svg");
  }

  &.home-icon {
    background-image: url("~src/assets/iconos/perfil_miperfil.svg");
  }

  &.home-icon {
    background-image: url("~src/assets/iconos/menu_home.svg");
  }

  &.news-icon {
    background-image: url("~src/assets/iconos/menu_noticias.svg");
  }

  &.community-icon {
    background-image: url("~src/assets/iconos/menu_comunidad.svg");
  }

  &.team-icon {
    background-image: url("~src/assets/iconos/menu_equipos.svg");
  }

  &.profile-icon {
    background-image: url("~src/assets/iconos/perfil_miperfil.svg");
  }

  &.posts-icon {
    background-image: url("~src/assets/iconos/perfil_misposts.svg");
  }

  &.topic-icon {
    background-image: url("~src/assets/iconos/perfil_mistopicscreados.svg");
  }

  &.saved-icon {
    background-image: url("~src/assets/iconos/perfil_misguardados.svg");
  }

  &.settings-icon {
    background-image: url("~src/assets/iconos/perfil_ajustes.svg");
  }

  &.readed-icon {
    background-image: url("~src/assets/iconos/icon_notireaded.svg");
  }

  &.notreaded-icon {
    background-image: url("~src/assets/iconos/icon_notinotreaded.svg");
  }

  &.all-icon {
    background-image: url("~src/assets/iconos/icon_noti.svg");
  }
}

.banner {
  position: relative;
  margin: 30px 0 50px;
  box-shadow: $boxshadow;
  background: {
    image: url("~src/assets/rectangulofoto.png");
    size: cover;
    position: center;
  };
  color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 30px 35% 30px 30px;
  @media (max-width: $md) {
    padding: 15px;
    padding-right: 40% !important;
  }

  h4, h3 {
    margin: 0;
  }

  p {
    margin-bottom: 0;

  }

  img {
    width: auto;
    right: 0;
    position: absolute;
    bottom: 0;
    object-fit: contain;
    object-position: bottom;
    height: calc(100% + 30px);
    @media (max-width: $md) {
      max-width: 35%;
    }
  }
}

.gray {
  color: $whiteText;
}

.green {
  color: $green !important;
}

.white {
  color: white;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

b {
  font-weight: 500;
}


* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  outline: none;
}

button {
  color: inherit;
  background: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
  @include font();
}

.main-padding {
  @include responsiveSpace(padding-left, 0.5);
  @include responsiveSpace(padding-right, 0.5);
  @include responsiveSpace(padding-top, 0.5);
  @include responsiveSpace(padding-bottom, 0.5);
}


.liga-editor {
  display: flex;
  flex-direction: column-reverse;

  .ql-editor.ql-blank::before {
    color: $whiteText;
  }

  .ql-editor, .ql-snow {
    background-color: $inputContainerColor !important;
    border: none !important;

    p {
      color: $whiteText;
    }
  }

  .ql-stroke {
    stroke: $whiteText;
  }
}

.input-box {
  //background: $darkMain;
  border-radius: 4px;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
}

.input-box-face {
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
}

.input-box-prepend-icon {
  background: $grayExtra;
  border-radius: 4px 0 0 4px;
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 30px;
  flex-basis: 30px;
  border: 1px solid $grayStroke;
  border-right: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-box-block {
  width: 100%;
  min-width: 200px;
}

.input-box-green-text {
  color: $green;
}

.input-box-white-text {
  color: #ffffff;
}

.prepend-icon {
  fill: $darkMain;
  stroke: $whiteText
}

.prepend-icon-valid {
  stroke: $green;
}

.prepend-icon-invalid {
  stroke: $red;
}

.succsess-feedback-message {
  stroke: $green;
}

.invalid-feedback-message {
  color: $red;
}

// Sobreescritura de los estilos de varios componentes de NgPrime

// General input text.
.p-inputtext {
  box-shadow: none;
  font-size: 1rem;
  background: $grayExtra;
  padding: 0.5rem 0.5rem;
  border: 1px solid $grayStroke;
  border-left: none;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  border-radius: 0 4px 4px 0;

  &::placeholder {
    color: $whiteText;
  }
}

.p-inputtext:enabled:hover {
  border-color: $grayStroke;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: $grayStroke;
}

// Dropdown.
.p-dropdown {
  background: $darkMain;
  border: none;
  border-radius: 4px;
}

.p-dropdown .p-dropdown-label {
  background: $darkMain;
}

.p-dropdown-panel {
  background: $darkMain;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  color: #fffdfc;
}

.p-dropdown .p-dropdown-trigger {
  background: $darkMain;
  color: $green;
}

.p-dropdown:not(.p-disabled).p-focus {
  border-color: transparent;
  box-shadow: none;
}

// Calendar.

// Calendar input text
.p-calendar > .p-inputtext {
  color: $green;
  box-shadow: none;
  border: none;
  padding: 10px;
  background: $darkMain;
  border-radius: 4px;

  &::placeholder {
    color: $whiteText;
  }
}

.p-datepicker {
  min-width: auto !important;
  z-index: 2001 !important;
  max-width: 90% !important;
  width: 450px !important;
  background-color: $grayExtra !important;

  .p-datepicker-header {
    background-color: $grayExtra !important;
    color: white;
  }

  span, select {
    background-color: $grayExtra !important;
    color: white;
  }
}

/// Calendar today selection.
.p-datepicker table td.p-datepicker-today > span {
  background: $green;
  color: #ffffff;
  border-color: transparent;
}

// Select box for months.
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
  border: 0;
  background-color: transparent;
  color: white;
  -webkit-appearance: none;
}

// Select box for years.
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year {
  margin-right: 0.5rem;
  border: 0;
  background-color: transparent;
  -webkit-appearance: none;
}

// Elimina el borde del control select al tener el foco.
.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background-color: rgba($green, 0.2);
}

// Spinners
.spinner {
  color: $green;
}

.spinner-sm {
  font-size: 1rem;
}

.spinner-md {
  font-size: 2.4rem;
}

.spinner-lg {
  font-size: 5rem;
}

.button-spinner {
  font-size: 1rem;
  color: #29EB4F;
}


.main-grid {
  max-width: $container;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 68% 32%;
  grid-gap: 25px;
  @media (max-width: $responsive) {
    display: flex;
    padding: 0 5%;
    flex-direction: column;
  }

  .back {
    @include responsiveSpace(margin-top, .5);
    @include responsiveSpace(margin-bottom);
    color: $green;
    .separator {
      margin: 0 0.5em;
      display: inline-block;
    }
    a {
      text-decoration: none;
      vertical-align: middle;
      color: inherit;
    }

    button {
      @media (max-width: $md) {
        padding: 0;
      }
    }

    img {
      margin-right: 15px;
      margin-left: -50px;
      vertical-align: middle;
      transform: rotate(90deg);
      @media (max-width: $md) {
        margin-left: 0;
      }
    }
  }

  .grid-right {
    grid-column: 2 / 3;
    display: flex;
    flex-direction: column;
    @media (max-width: $responsive) {
      display: none;
    }

    &.pt-50 {
      padding-top: 50px;
    }

    &.pt-100 {
      padding-top: 100px;
    }

    &.start-2 {
      grid-row: 2 / auto;
    }

  }

  .grid-left {
    grid-column: span 1;
    grid-column-start: 1;
    display: flex;
    flex-direction: column;

    &.pt-50 {
      padding-top: 50px;
    }

    &.pt-100 {
      padding-top: 100px;
    }
  }

  .grid-full {
    grid-column: span 3;
  }
}

//Inputs Radio
.radio-input {
  position: relative;
  display: flex;
  align-items: center;

  label {
    position: static;

    &:before {
      position: absolute;
      left: 0;
      top: 1px;
      height: 13px;
      width: 13px;
      content: '';
      border-radius: 50%;
      border: 1px solid white;
    }
  }

  input {
    appearance: none;
    height: 7px;
    margin-left: 4px;
    margin-right: 1em;
    width: 7px;
    border-radius: 50%;
    background: transparent;

    &:checked {
      background: $green;

      ~ label {
        &:before {
          border-color: $green;
        }
      }
    }
  }
}

//Inputs con un icono
.icon-input {
  position: relative;

  input {
    @include input;
    padding-left: 2.25em !important;

    &:not(:placeholder-shown) {
      ~ img {
        filter: none;
        opacity: 1;
      }
    }
  }

  img {
    pointer-events: none;
    height: 0.85em;
    width: 0.85em;
    object-fit: contain;
    position: absolute;
    left: 0.75em;
    top: 0.8em;
    opacity: 0.8;
    filter: grayscale(100);
  }

  &.bg-gray {
    input {
      background: $grayExtra;
    }
  }
}

.icon-text {
  img {
    display: inline-block;
    vertical-align: middle;
    margin: 0 4px;
  }
}

//MODALS
.p-dialog {
  //background-color: $darkContainer;


  &.team-select {
    max-width: 650px;
    @media (max-width: $responsiveMenu) {
      max-width: none;
      min-width: 90%;
    }
  }

  &.password-reset {
    .p-dialog-content {
      padding: 0;
    }
  }

  .p-dialog-content {
    background-color: $darkContainer;
  }
}


//BLOQUES
section.controls {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid $whiteText;

  .buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @media (max-width: $responsiveMenu) {
      display: flex;
      justify-content: space-between;
    }

    button, a {
      margin-right: 1.2em;
      color: white;
      transition: all 0.2s ease;

      &:hover {

      }

      &.active {
        @include roundBottom(black, $green, $green);
      }
    }
  }

  .filters-dropdown {
    margin-top: 40px;
  }

  .filters {
    color: white;
    margin-top: 40px;

    p {
    }
  }
}

.post {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid $grayStroke;
  padding: 20px;
  background: $containerColor;
  color: $whiteText;

  h4, h3 {
    color: white;
    margin: 0;
    font-weight: 500;

    a, b, span {
      color: inherit;
      text-decoration: none;
    }
  }

  p.source {
    a {
      color: inherit;
      text-decoration: none;
    }

    span {
      display: inline-block;
      margin: 0 1em;
    }

    img {
      vertical-align: middle;
      margin-right: 0.5em;
      height: 15px;
      width: 15px;
      object-fit: contain;
    }
  }

  &.new-min {
    position: relative;
    padding: 90px 12px 12px 12px;
    text-decoration: none;

    .content {
      position: relative;
      z-index: 3;

      p {
        margin: 0;
        font-weight: 500;
        color: white;

        &.main {
          margin: 8px 0;
          height: 3.5em;
        }

        &.min {
          font-weight: normal;

          &:last-of-type {
            text-decoration: underline;
          }

          img {
            width: 15px;
            margin-right: 5px;
            height: 15px;
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }

    .img {
      z-index: 1;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      position: absolute;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        bottom: 0;
        height: 80%;
        z-index: 2;
        background: linear-gradient(to bottom, rgba($darkMain, 0.001) 0%, rgba($darkMain, 1) 100%);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &.new {
    .content {
      display: flex;

      .info {
        flex-direction: column;
        justify-content: center;
        display: flex;

        h4 {
          margin-bottom: 1em;
        }
      }

      > img {
        margin-right: 15px;
        width: 150px;
        border-radius: 4px;
        object-fit: cover;
        height: 150px;
      }

    }
  }

  &.community {
    background: $containerColor;
    border: none;
    //padding-bottom: 40px;
    &:after {
      height: 1px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      //content: '';
      background: white;
    }

    .topic-img {
      position: absolute;
      top: 0;
      left: 0;
      height: 33%;
      z-index: 1;
      width: 100%;

      img {
        opacity: 0.4;
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: grayscale(100);
      }

      &:after {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 2;
        content: '';
        background: linear-gradient(to bottom, rgba(white, 0) 0%, rgba($containerColor, 1) 85%);
      }

    }

    .row {
      display: flex;
      width: 100%;
      position: relative;
      z-index: 5;

      a {
        text-decoration: none;
      }

      h4 {
        margin: 0;
      }

      p {
        color: $whiteText;
        margin: 0;

        a {
          color: inherit;
          text-decoration: none;
        }
      }
    }

    .actions {
      margin-top: 20px;
      display: flex;
      align-items: center;

      .controls {
        button {
          padding: 5px;
          border-radius: 7px;
          margin-right: 15px;

          &.active {
            background: $green;
            color: white;

            &.like {
              img {
                filter: brightness(100);
              }
            }
          }

          img {
            margin: 0 4px;
          }
        }
      }

      .save {
        margin-left: auto;
        padding: 0;

        &:hover, &.active {
          svg {
            path {
              fill: $green;
              stroke: $green;
            }
          }
        }

        svg {
          path {
            fill: none;
            stroke: white;
          }
        }
      }
    }

    .media {
      margin-top: 20px;

      .share {
        margin-left: auto;

        &:hover {
          img {
            filter: none;
            opacity: 1;
          }

          color: white;
        }

        img {
          filter: brightness(100);
          opacity: 0.5;
          vertical-align: middle;
          margin-right: 5px;
        }
      }

      .reactions {
        display: flex;
        align-items: center;

        button {
          border-radius: 12px;
          background: $darkContainer;
          padding: 6px 10px;
          margin-right: 15px;

          &.active {
            background: $green;
            color: white;
          }

          img, span {
            display: inline-block;
            margin-right: 3px;
          }
        }
      }
    }

    .media-content {
      img, video {
        margin-bottom: 15px;
        width: 100%;
        max-height: 400px;
        object-fit: cover;
        @media (max-width: $md) {
          max-height: 300px;
        }
      }

      img {
        border-radius: 4px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-decoration: none;
      color: white;

      img {
        max-height: 400px;
        object-fit: cover;
        border-radius: 12px;
      }

      h4 {
        p {
          margin: 0;
        }
      }

      p {
        color: white;
      }
    }

    .first {
      color: white;
      align-items: center;
      margin-bottom: 0.5em;
      img {
        margin-right: 0.25em;
        display: inline-block;
        vertical-align: top;
      }
    }

    .header {
      margin-bottom: 15px;
    }

    button {
      padding: 0;
    }

    .labels {
      margin-left: auto;
      display: flex;
      align-items: center;

      .label {
        border-radius: 8px;
        padding: 5px 10px;
        margin-left: 10px;
        border-color: $green;

        &.red {
          background: $red;
          color: white;
        }

        &.join {
          display: flex;
          border: 2px solid $green;
          color: $green;

          svg {
            path {
              stroke: $green;
            }
          }
        }
      }
    }

    &.small {
      display: flex;
      flex-direction: row;
      @media (max-width: $xs) {
        flex-direction: column;
      }

      p, button, .left, .right {
        font-size: 0.8rem;
      }

      .content {
        font-size: 0.95rem;

        p, a {
          font-size: inherit;
        }
      }

      .right {
        width: 100%;

        .actions {
          margin-bottom: 5px;
        }

        .header {
          margin-bottom: 5px;
        }
      }

      .left {
        position: relative;

        .media-content {
          .link-preview, img, video {
            margin-right: 20px;
            width: 220px;
            height: 120px;
            object-fit: cover;
            @media (max-width: $xs) {
              width: 100%;
              height: 300px;
              margin-bottom: 30px;
              margin-right: 0;
            }
            //max-width: 100%;
          }

          .link-preview {
            display: flex;
            background: $darkMain;
            height: 100%;
            align-items: center;
            flex-direction: column;
            padding: 15px;

            .content {
              width: 100%;
              padding: 0;

              p {
                max-width: 180px;
                margin: 5px auto;
              }
            }

            img {
              margin: 0;
              width: 180px;
              height: 80px;
              @media (max-width: $xs) {
                width: 100%;
                height: 200px;
              }
            }
          }
        }
      }
    }

  }

  &.community-min {
    border: none;
    margin-bottom: 8px;
    position: relative;
    border-radius: 6px;
    padding: 8px 6px 8px 30px;
    text-decoration: none;
    background: {
      color: $grayExtra;
      position: right;
    };

    > img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      filter: grayscale(100);
      object-fit: cover;
      z-index: 0;
    }

    &:after {
      z-index: 1;
      height: 80%;
      top: 10%;
      width: 3px;
      border-radius: 1px;
      content: '';
      position: absolute;
      left: 10px;
      background: rgba($whiteText, 0.5);
    }

    .name {
      display: inline-block;
      margin-bottom: 10px;
    }

    p, a {
      margin: 0;
      position: relative;
      z-index: 4;

      &:first-of-type {
        margin-bottom: 0.5em;
      }

      img {
        vertical-align: baseline;
        margin: 0 0.5em 0 0;
      }

      span {
        display: inline-block;
        margin-bottom: 10px;
        color: $whiteText;
      }
    }

    &:before {
      z-index: 2;
      border-radius: 4px;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(to left, rgba($grayExtra, 0) 0%, rgba($grayExtra, 1) 60%, rgba($grayExtra, 1) 100%);;
    }

    * {
      position: relative;
      z-index: 1;
    }

    &.on-team {
      padding: 30px 50px 20px;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      vertical-align: middle;

      .left, .right {
        position: relative;
        z-index: 4;
      }

      .left {
        a {
          img {
            vertical-align: middle;
            margin-right: 0.3em;
          }
        }

        > p {
          margin-top: 0.5em;
        }
      }

      .right {
        a, button {
          @include roundBottom($green, transparent, $green);

          &.active {
            @include roundBottom(white, transparent, white);
          }

          &.joined {
            border-color: white;
            color: white;
          }

          svg {
            margin-left: 5px;
          }
        }
      }

      &:after {
        height: 70%;
        top: 15%;
        left: 20px;
        width: 3px;
        background: {
          color: $whiteText;
        };
        position: absolute;
        content: '';
      }

      p, a {
        text-decoration: none;
        color: white;
      }
    }
  }

  .item {
    margin-bottom: 8px;
    position: relative;
    border-radius: 4px;
    padding: 12px 8px;
    background: {
      color: $grayExtra;
      size: 50% auto;
      position: right;
    };

    &:before {
      z-index: 0;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(to left, rgba($grayExtra, 0) 0%, rgba($grayExtra, 1) 40%, rgba($grayExtra, 1) 100%);;
    }

    &:first-of-type {
      margin-top: 30px;
    }

    * {
      position: relative;
      z-index: 1;
    }
  }

}


.advertising {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background: $grayExtra;
  //@include responsiveSpace(margin-top, 0.5);

  p {
    @include pmin;
    text-align: center;
    color: $whiteText;
    text-decoration: none;
  }

  img {
    padding: 5px;
    width: 100%;
    border-radius: 8px;
    padding-top: 0;
  }
}

.no-results {
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 0;
    color: white;

    a {
      color: white;
      text-decoration: underline;
    }
  }

  img {
    &:first-child {
      margin-bottom: 30px;
    }

    &:last-child {
      filter: brightness(100);
      margin-top: 15px;
    }
  }
}


.filters-dropdown {
  display: flex;
  align-items: center;

  img {
    width: 2em;
    margin-right: 0.5em;
  }

  span {
    display: inline-block;
    color: white;
    margin-right: 0.5em;
  }

  .p-dropdown {
    > span, .p-dropdown-trigger span {
      color: $whiteText;
      margin-right: 0;
      padding: 0;

      &:before {
        color: inherit
      }
    }

    .p-dropdown-item {
      &.p-highlight {
        background: white;

        span {
          color: black !important;
        }
      }

      &:hover:not(.p-highlight) {
        border: 1px solid $green;
        background: $darkMain !important;

        span {
          color: white !important;
        }
      }
    }
  }
}
.clean-menu {
  .p-menu-list {
    padding-top: 0 !important;
    margin-top: 0 !important;

    .p-submenu-header {
      display: none;
    }
  }
}
